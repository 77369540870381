import React, { useState, useEffect } from 'react';
import '../App.css';
import navlogo from "../img/logo.svg";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // Adjust the scroll threshold as needed
      if (scrollTop > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false); // Close the navbar when the location changes
  }, [location]);

  

  return (
    <div className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className={`container ${isOpen ? 'open' : ''}`}>
       <Link to="/" ><div className='Navlogo'>
          <img src={navlogo} alt="" style={{ width: "100%" }} />
        </div></Link> 
        <button className={`menu-btn ${isOpen ? 'open' : ''}`} onClick={toggleMenu} />
        <div className={`backdrop ${isOpen ? 'open' : ''}`} />
        <div className={`menu ${isOpen ? 'open' : ''}`}>
          <nav>
            <Link to="/" className="link">
              Home
            </Link>
            <Link to="/AboutPage" className="link">
              About
            </Link>
            <Link to="/services" className="link">
              Services
            </Link>
            <Link to="/ProjectPage" className="link">
              Projects
            </Link>
            <Link to="/contact" className="link">
              Contact Us
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
