import '../App.css'
import logo from '../img/logo.svg'

function Loading(){
    return (
<div>
<div className='load-img' >
<img src={logo} className='load-image'></img>
</div>
</div>
    );
}
 export default Loading;