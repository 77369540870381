import React from 'react';

const Modal = (props) => {
  const { isOpen, closeModal } = props;
  const { content } = props;

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button className='modalButton' onClick={closeModal} >✖</button>
            <div className="modal-content">
              <div className="aboutPRO">
               <div className="headddPRo"> HOME ARCHITECTURERESIDENCE AT INDERPURI</div>
               <h4 className="hhad">Residence at : <span className='colortextP'> Inderpuri</span></h4>
               <h4 className="hhad"> CLIENT:    <span className='colortextP'> Mr. Singh's Residence</span></h4>
                  <h4 className="hhad">YEAR:<span className='colortextP'> Ongoing</span></h4>
                  <h4 className="hhad">PROJECT:  <span className='colortextP'>Residence</span> </h4>
                  <h4 className="hhad">AREA:  <span className='colortextP'> 4500 Sq. Ft.</span></h4>
                  <h4 className="hhad">LOCATION:  <span className='colortextP'>Inderpuri</span> </h4>
                  <h4 className="hhad">SERVICE: <span className='colortextP'>  Architecture & Interior Audit</span></h4>
              </div>
              <div className='content'>{content}</div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
